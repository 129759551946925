/* eslint-disable @typescript-eslint/no-misused-promises */
'use client'
import { OptimizedImage } from '@/app/ui/parts/default/common/originalImage'
import { featuredItemType, itemType } from '@/lib/item/server'
import { serviceType } from '@/lib/service'
import { ChevronRight } from 'lucide-react'
import React from 'react'

type ItemCardProps = {
  service?: serviceType
  item: itemType
  featuredItem?: featuredItemType
  index?: number
}

export const FeaturedItemCard: React.FC<ItemCardProps> = ({
  item,
  featuredItem,
}) => {
  if (!featuredItem) return null
  return (
    <div className="p-1 w-full mb-6 sm:w-1/3">
      <div className="relative">
        <a href={`/item/${item.item_id}`}>
          <OptimizedImage
            src={featuredItem?.image_url}
            alt={item.name}
            height={1000}
            width={1000}
            className="featured-item-image w-full h-auto object-cover"
          />
        </a>
      </div>
      <div className="bg-[#eee] p-4">
        <a href={`/item/${item.item_id}`}>
          <h3 className="text-sm font-bold">{item.name}</h3>
          <div className="flex items-center mt-2">
            <p className="text-xs text-gray-500">
              {item.description.slice(0, 60)}...
            </p>
            <ChevronRight
              strokeWidth={'3px'}
              width={25}
              height={25}
              color="gray"
            />
          </div>
        </a>
      </div>
    </div>
  )
}
